<template>
    <div>
        <draggable
            :list="listData"
            ghost-class="ghost"
            handle=".item_dragger"
            @start="drag = true"
            @end="onEnd"
        >
            <template v-for="(listItem, index) in listData">
                <div class="input-group margin-top-10" :key="index">
                    <span class="input-group-addon">
                        <span class="glyphicon glyphicon-move dragger item_dragger"></span>
                    </span>
                    <input type="text" class="form-control" v-model="listItem.display" />
                    <span class="input-group-btn">
                        <a class="btn-danger btn" @click="removeRow(index)">
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </a>
                    </span>
                </div>
            </template>
        </draggable>

        <div class="margin-top-20">
            <a @click="addRow" href="#" class="blue">
                <i class="fas fa-plus" aria-hidden="true"></i> Add New Value
            </a>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import _ from 'lodash';

export default {
    props: ['data'],
    data() {
        const listData = this.data || [];

        return {
            listData,
        };
    },
    methods: {
        // Actions
        addRow(e) {
            e.preventDefault();

            const key = _.uniqueId();
            this.listData.push({ key: key, display: '', new: true });
        },
        removeRow(index) {
            if (index !== -1) {
                this.listData.splice(index, 1);
            } else {
                console.warn(`Cannot find key ${key}`);
            }
        },
        onEnd() {},
    },
    watch: {
        listData: {
            handler() {
                let returnData = [];
                this.listData.forEach((item, index) => {
                    let itemDisplay = item.display.trim();
                    let itemKey = item.new
                        ? itemDisplay.replace(/\s+/g, '_').toLowerCase()
                        : item.key;

                    if (itemDisplay === '') {
                        return;
                    }

                    // Check to make sure there are no unique keys
                    let uniqueKey = true;
                    let itemIndex = null;
                    do {
                        uniqueKey = true;

                        this.listData.forEach((checkItem, checkIndex) => {
                            if (checkIndex !== index) {
                                let checkKey = checkItem.key;
                                let itemKeyWithIndex =
                                    itemIndex === null
                                        ? itemKey
                                        : itemKey + '_' + itemIndex.toString();

                                if (checkKey === itemKeyWithIndex) {
                                    uniqueKey = false;
                                }
                            }
                        });

                        if (!uniqueKey) {
                            if (itemIndex === null) {
                                itemIndex = 0;
                            } else {
                                itemIndex++;
                            }
                        }
                    } while (!uniqueKey);

                    if (itemIndex !== null) {
                        itemKey += '_' + itemIndex.toString();
                    }

                    item.key = itemKey;

                    // Just use the display as the key for now
                    const newItem = { key: itemKey, display: itemDisplay };
                    returnData.push(newItem);
                });

                this.$emit('list-data-changed', returnData);
            },
            deep: true,
        },
    },
};
</script>
