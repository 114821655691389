<template>
    <div>
        <div v-if="(data.messages || []).length" class="alert alert-info">
            <template v-for="message in data.messages || []">
                {{ message }}
            </template>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <label for="name[EN]" class="control-label">Name</label>
                <input type="text" v-model="name.EN" class="form-control required" placeholder="Name" />
            </div>
        </div>

        <table class="table table-responsive table-valign-middle table-data-source margin-top-20" id="table_data_source">
            <thead>
                <tr>
                    <th></th>
                    <th v-for="field in rowFields" :key="field.key">
                        <span v-html="field.value.EN" />
                    </th>
                    <th>Attributes</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <draggable
                :list="customFields"
                ghost-class="ghost"
                handle=".item_dragger"
                @start="drag=true"
                @end="onEnd"
                tag="tbody"
                >
                <tr v-for="customField in customFields" :key="customField.key">
                    <td><span class="glyphicon glyphicon-move dragger item_dragger"></span></td>
                    <td v-for="rowField in rowFields" :key="rowField.key">
                        <template v-if="rowField.key === 'values'">
                            <a @click="editValues($event, customField)" :class="`btn btn-sm btn-primary ${!hasSelectableValues(customField.type) ? 'disabled': ''}`" :disabled="!hasSelectableValues(customField.type)">
                                <span class="fas fa-list"></span>
                            </a>
                        </template>
                        <template v-else-if="rowField.key === 'type'">
                            <select @change="selectType($event, customField)" v-model="customField.type" class="form-control">
                                <template v-for="(label, optionKey) in rowField.values.basic">
                                    <option :value="optionKey" :disabled="isFieldTypeOptionDisabled(customField, optionKey)" :key="optionKey" >
                                        {{ label }}
                                    </option>
                                </template>
                            </select>
                        </template>
                        <template v-else>
                            <input type="text"
                                v-model="customField.value[rowField.key.replace(/.*\[(.*)\]/g, (match, p1) => `${p1}`)]"
                                :data-validate="rowField['data-validate']"
                                :data-length="rowField['data-length']"
                                class="form-control" />
                        </template>
                    </td>
                    <td>
                        <a @click="editAttributes($event, customField)" class="btn btn-sm btn-primary attributes_edit">
                            <span class="fas fa-pencil-alt"></span>
                        </a>
                    </td>
                    <td>
                        <a @click="removeCustomField(customField.key)" href="Javascript:void(0);" class="btn-danger btn btn-sm">
                            <i class="fas fa-trash" aria-hidden="true"></i>
                        </a>
                    </td>
                </tr>
            </draggable>
        </table>

        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <a @click="addCustomField" href="Javascript:void(0);" class="blue margin-top-10 btn btn-sm btn-primary">
                        <i class="fas fa-plus" aria-hidden="true"></i> Add New Field
                    </a>
                </div>
                <div class="row">
                    <div class="col-sm-6 margin-top-10">
                        <b>Other Features</b>
                        <div v-if="availableFeaturesMap.status" class="col-sm-12">
                            <input type="checkbox" v-model="selectedFeaturesMap.status" />
                            Status (New records require approval)
                        </div>
                        <div v-if="availableFeaturesMap.expire_date" class="col-sm-12">
                            <input type="checkbox" v-model="selectedFeaturesMap.expire_date" />
                            Expiry Date (Turn on record expiration options)
                        </div>
                        <div v-if="availableFeaturesMap.gps" class="col-sm-12">
                            <input type="checkbox" v-model="selectedFeaturesMap.gps" />
                            Location (Store GPS location of user's device)
                        </div>
                    </div>

                    <div class="col-sm-6 margin-top-10">
                        <b>External API Settings:</b><br>
                        <div class="margin-top-10">
                            <a @click="toggleApiSettings" href="Javascript:void(0);" class="blue">
                                View Details
                            </a>
                        </div>
                        <div v-if="apiSettingsVisible" class="margin-top-10">
                            Data Source: {{ id }}<br>
                            Entity: {{ data.database_name }}<br><br>
                            Fields:<br>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Field</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="customField in customFields" :key="customField.key">
                                        <template v-if="!availableFeaturesMap[customField.key]">
                                            <td>{{ customField.key }}</td>
                                            <td>{{ getFieldLabel(customField) }}</td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <input :value="vueJsonData" type="hidden" name="__vue_json_data" />
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import _ from 'lodash';

    export default {
        props: ['data'],
        computed: {
            vueJsonData() {
                const data = {
                    name: this.name,
                    fields: this.customFields,
                    features: this.selectedFeaturesMap
                };

                return JSON.stringify(data);
            }
        },
        data() {
            const data = this.data;

            const id = data._id;
            const nameEN = _.get(data, 'name.EN', '');
            const dsFields = data.ds_fields;
            const fields = data.fields;
            const fieldTypeDefinitions = data.field_type_definitions ?? [];
            const availableFeaturesMap = data.available_features;
            const selectedFeaturesMap = data.ds_feature_fields || {};
            const fieldTypesMap = data.field_types;
            const recordsCount = data.records_count;

            const customFields = _.filter(fields, (field) => {
                return !availableFeaturesMap[field.key];
            })

            const attributeFields = _.filter(dsFields, (field) => {
                return !!field.attribute;
            });

            const rowFields = _.filter(dsFields, (field) => {
                return !field.attribute;
            });

            return {
                id,
                name: { EN: nameEN },
                customFields,
                attributeFields,
                rowFields,
                availableFeaturesMap,
                selectedFeaturesMap,
                fieldTypesMap,
                fieldTypeDefinitions,
                recordsCount,
                apiSettingsVisible: false
            };
        },
        methods: {
            getCompanyLanguages() {
                return this.data.company.settings.languages;
            },
            getFieldLabel(customField) {
                var label = null;
                this.getCompanyLanguages().forEach((language) => {
                    if (!label) {
                        label = customField.value[language];
                    }
                });

                return label;
            },
            isFieldTypeOptionDisabled(customField, optionKey) {
                return !!(this.recordsCount && customField.type && optionKey !== customField.type);
            },
            hasSelectableValues(fieldType) {
                return ['select_list', 'product_list', 'multi_select'].includes(fieldType);
            },
            findCustomFieldIndex(fieldKey) {
                const index = _.findIndex(this.customFields, (field) => {
                    return field.key === fieldKey;
                });

                return index;
            },
            // Actions
            editValues(event, field) {
                const target = $(event.target);
                if (target.attr('disabled') !== 'disabled') {
                    var tr = target.closest('tr');

                    var $trigger = $('<a>', {
                        'class': 'btn btn-sm btn-primary btn-subaction hidden',
                        'data-idval': this.id,
                        'data-encoded': Utils.encodeData({ field_key: field.key, field_type: field.type, field_value: field.values || [] }),
                        'data-crud': '1',
                        'data-option': '',
                        'data-path': 'tenant-company/content/data-source',
                        'data-route': 'values-add',
                        'data-modal-title': 'Add/Edit Values',
                        'data-modal-props': Utils.encodeData({ field: field }),
                        'data-script': 'data_source_values_add_edit',
                        'data-modal': '1',
                        'data-action': 'add',
                        'data-method': 'POST',
                        'href': '#'
                    });

                    tr.append($trigger);
                    $trigger.trigger('click');
                    $trigger.remove();
                }
            },
            editAttributes(event, field) {
                const $target = $(event.target);
                var $tr = $target.closest('tr');
                var rowIndex = $tr.index();

                var attributes = {};
                $.each(this.attributeFields, function (i, key) {
                    attributes[key] = field[key];
                });

                var name = this.getFieldLabel(field);
                name = !StringUtils.isBlank(name) ? 'Edit ' + name + ' Attributes' : 'Edit Field Attributes';

                var $trigger = $('<a>', {
                    'class': 'btn btn-primary btn-subaction hidden',
                    'data-idval': this.id,
                    'data-crud': '0',
                    'data-option': '',
                    'data-encoded': Utils.encodeData($.extend({}, {version: this.data.version, field_key: field.key, field_type: field.type}, attributes)),
                    'data-path': 'tenant-company/content/data-source',
                    'data-route': 'field-attributes',
                    'data-modal-title': name,
                    'data-modal-props': Utils.encodeData({ field: field }),
                    'data-script': 'data_source_attributes_edit',
                    'data-modal': '1',
                    'data-action': 'edit',
                    'data-method': 'POST',
                    'href': '#'
                });

                $tr.append($trigger);
                $trigger.trigger('click');
                $trigger.remove();
            },
            addCustomField() {
                let newField = {
                    key: _.uniqueId("__"),
                    type: '',
                    value: {}
                };

                this.getCompanyLanguages().forEach((language) => {
                    newField.value[language] = '';
                });

                //Apply top level defaults for attribute fields
                this.attributeFields.forEach((field) =>{
                    newField[field.key] = field.default;
                });

                this.customFields.push(newField);
            },
            removeCustomField(fieldKey) {
                const index = this.findCustomFieldIndex(fieldKey);
                this.customFields.splice(index, 1);
            },
            selectType(event, field) {
                const selectedFieldType = event.target.value;
                const fieldTypeDefinition = this.fieldTypeDefinitions.find(item => item.key === selectedFieldType);

                //Apply defaults for selected field type
                if (fieldTypeDefinition?.defaults) {
                    const defaults = fieldTypeDefinition.defaults;
                    Object.keys(defaults).forEach(attribute => field[attribute] = defaults[attribute]);
                }
            },
            toggleApiSettings() {
                this.apiSettingsVisible = !this.apiSettingsVisible;
            },
            // Events
            onEnd() {}
        },
        mounted() {
            $('body').on(CUSTOM_EVENTS.DATA_SOURCE_FIELD_ATTRIBUTES_SAVE, (event, formData) => {
                const index = this.findCustomFieldIndex(formData.field.key);
                this.customFields.splice(index, 1, formData.field);
            });

            $('body').on(CUSTOM_EVENTS.DATA_SOURCE_FIELD_VALUES_SAVE, (event, formData) => {
                const index = this.findCustomFieldIndex(formData.field.key);
                this.customFields.splice(index, 1, formData.field);
            });
        }
    }
</script>
