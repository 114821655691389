var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid", attrs: { id: "add_field_container" } },
    [
      _c("form", { attrs: { id: "customform" } }, [
        _c("h4", { staticClass: "margin-top-20" }, [
          _vm._v("\n            Add a New Field\n        "),
        ]),
        _vm._v(" "),
        _c("fieldset", [
          _c(
            "div",
            { staticClass: "row" },
            [
              _vm._l(_vm.companyLanguages, function (companyLanguage) {
                return [
                  _c(
                    "div",
                    {
                      key: companyLanguage.key,
                      staticClass: "col-sm-3 margin-top-05",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: companyLanguage.key },
                        },
                        [
                          companyLanguage.key === "EN" && _vm.language !== "EN"
                            ? _c(
                                "span",
                                { staticStyle: { "vertical-align": "middle" } },
                                [_vm._v("Internal ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(companyLanguage.label),
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.names[companyLanguage.key],
                            expression: "names[companyLanguage.key]",
                          },
                        ],
                        class: _vm.getAddFieldClass(companyLanguage.key),
                        attrs: {
                          type: "text",
                          placeholder: `Field Name ${companyLanguage.key}`,
                        },
                        domProps: { value: _vm.names[companyLanguage.key] },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.names,
                              companyLanguage.key,
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]
              }),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-3 margin-top-05" }, [
                _c(
                  "label",
                  { staticClass: "control-label", attrs: { for: "type" } },
                  [_vm._v("Field Type")]
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fieldType,
                        expression: "fieldType",
                      },
                    ],
                    staticClass: "form-control required",
                    attrs: {
                      name: "type",
                      placeholder: "Field Type",
                      "data-length": "3000",
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.fieldType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _vm._l(_vm.fieldOptions, function (option) {
                      return [
                        _c(
                          "option",
                          { key: option.key, domProps: { value: option.key } },
                          [_vm._v(_vm._s(option.label))]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 margin-top-10" }, [
              _c(
                "a",
                { staticClass: "btn-primary btn", on: { click: _vm.addField } },
                [_vm._v("Add")]
              ),
              _vm._v(" "),
              _vm.addFieldStatusSuccess !== null
                ? _c(
                    "span",
                    {
                      class: _vm.addFieldStatusSuccess
                        ? "text-success"
                        : "text-danger",
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.addFieldStatusMessage) +
                          "\n                    "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }