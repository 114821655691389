var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-fluid",
      attrs: { id: "reorder_fields_container" },
    },
    [
      !_vm.isReadOnly()
        ? _c("div", { staticClass: "row" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "pull-right" }, [
              _c(
                "a",
                {
                  attrs: { href: "Javascript:void(0);" },
                  on: { click: _vm.selectAllFields },
                },
                [_vm._v("\n                Select All\n            ")]
              ),
              _vm._v(" | \n            "),
              _c(
                "a",
                {
                  attrs: { href: "Javascript:void(0);" },
                  on: { click: _vm.clearAllFields },
                },
                [_vm._v("\n                Clear All\n            ")]
              ),
              _vm._v("  \n            "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-action btn-sm",
                  attrs: {
                    href: "Javascript:void(0);",
                    "data-action": "edit",
                    "data-crud": "1",
                    "data-path": "tenant-company/content",
                    "data-route": "module-clone-manage-fields-from-other",
                    "data-modal-title": "Copy Field Settings From Module",
                    "data-idval": _vm.id,
                    "data-id": "id",
                    "data-script": "module_clone_manage_fields",
                    "data-modal": "1",
                    "data-option": _vm.doption,
                    "data-toggle": "tooltip",
                    "data-container": "body",
                    title: "Copy Field Settings From Module",
                  },
                },
                [_vm._v("\n                Copy from ...\n            ")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "form",
          {
            attrs: {
              id: "adminform",
              method: "POST",
              action: "tenant-company/content/module-columns-dynamic/" + _vm.id,
            },
          },
          [
            _c("input", {
              attrs: { name: "_method", type: "hidden", value: "PUT" },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { name: "doption", type: "hidden" },
              domProps: { value: _vm.doption },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.reorderJson,
                  expression: "reorderJson",
                },
              ],
              attrs: { name: "reorder", type: "hidden" },
              domProps: { value: _vm.reorderJson },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.reorderJson = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.deletedFieldsJson,
                  expression: "deletedFieldsJson",
                },
              ],
              attrs: { name: "deleted_fields", type: "hidden" },
              domProps: { value: _vm.deletedFieldsJson },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.deletedFieldsJson = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mappingFieldsJson,
                  expression: "mappingFieldsJson",
                },
              ],
              attrs: { name: "mapping_fields", type: "hidden" },
              domProps: { value: _vm.mappingFieldsJson },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.mappingFieldsJson = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "draggable",
              {
                staticClass: "drag_area",
                attrs: {
                  list: _vm.listData,
                  group: "group",
                  "ghost-class": "ghost",
                  handle: ".group_dragger",
                  move: _vm.onCheckGroupItemMove,
                  emptyInsertThreshold: "5",
                  disabled: _vm.isReadOnly(),
                },
                on: {
                  start: function ($event) {
                    _vm.drag = true
                  },
                  end: _vm.onEnd,
                },
              },
              [
                _vm._l(_vm.listData, function (groupItem) {
                  return [
                    _c(
                      "div",
                      { key: groupItem.group },
                      [
                        groupItem.group === 1
                          ? [
                              _c(
                                "div",
                                {
                                  key: groupItem.group + "_message",
                                  staticClass: "margin-top-20 margin-bottom-10",
                                },
                                [
                                  _vm._v(
                                    "Group 1 fields can only be reordered using drag and drop. Fields cannot be moved into or out of this group."
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        groupItem.group === 2
                          ? [
                              _c(
                                "div",
                                {
                                  key: groupItem.group + "_message",
                                  staticClass: "margin-top-20 margin-bottom-10",
                                },
                                [
                                  _vm._v(
                                    "For all groupings below, both the order and groupings of fields can be modified using drag and drop."
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "o_group list-group-item list-group-header",
                          },
                          [
                            groupItem.group > 2
                              ? _c("span", {
                                  staticClass:
                                    "glyphicon glyphicon-move dragger group_dragger",
                                })
                              : !_vm.isReadOnly()
                              ? _c("span", {
                                  staticClass:
                                    "glyphicon glyphicon-move dragger item_dragger",
                                  staticStyle: { visibility: "hidden" },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n                               Group " +
                                _vm._s(
                                  _vm.getCurrentGroupNum(groupItem.group)
                                ) +
                                "\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "draggable",
                          {
                            key: groupItem.group + "_list_group_item",
                            staticClass: "drag_area",
                            attrs: {
                              list: groupItem.fields,
                              group: "list_item",
                              "ghost-class": "ghost",
                              handle: ".item_dragger",
                              move: _vm.onCheckListItemMove,
                              emptyInsertThreshold: "5",
                              draggable: ".draggable",
                              disabled: _vm.isReadOnly(),
                            },
                            on: {
                              start: function ($event) {
                                _vm.drag = true
                              },
                              end: _vm.onEnd,
                            },
                          },
                          [
                            _c(
                              "transition-group",
                              { staticClass: "drag_span" },
                              [
                                _vm._l(groupItem.fields, function (fieldKey) {
                                  return [
                                    _vm.isValidField(fieldKey)
                                      ? _c(
                                          "li",
                                          {
                                            key: fieldKey,
                                            class:
                                              (_vm.isFieldDraggable(
                                                groupItem.group,
                                                fieldKey
                                              )
                                                ? "draggable"
                                                : "") +
                                              " o_item list-group-item field-info",
                                            attrs: { "data-key": fieldKey },
                                          },
                                          [
                                            _vm.isFieldDraggable(
                                              groupItem.group,
                                              fieldKey
                                            )
                                              ? _c("span", {
                                                  staticClass:
                                                    "glyphicon glyphicon-move dragger item_dragger",
                                                })
                                              : _c("span", {
                                                  staticClass:
                                                    "glyphicon glyphicon-move dragger item_dragger",
                                                  staticStyle: {
                                                    visibility: "hidden",
                                                  },
                                                }),
                                            _vm._v(
                                              "\n                                           \n                                        "
                                            ),
                                            !_vm.isFieldRequired(fieldKey)
                                              ? [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.enabledFields,
                                                        expression:
                                                          "enabledFields",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      name: "input_fields[]",
                                                      disabled:
                                                        _vm.isFieldRequired(
                                                          fieldKey
                                                        ) || _vm.isReadOnly(),
                                                    },
                                                    domProps: {
                                                      value: fieldKey,
                                                      checked: Array.isArray(
                                                        _vm.enabledFields
                                                      )
                                                        ? _vm._i(
                                                            _vm.enabledFields,
                                                            fieldKey
                                                          ) > -1
                                                        : _vm.enabledFields,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm.enabledFields,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = fieldKey,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              (_vm.enabledFields =
                                                                $$a.concat([
                                                                  $$v,
                                                                ]))
                                                          } else {
                                                            $$i > -1 &&
                                                              (_vm.enabledFields =
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  ))
                                                          }
                                                        } else {
                                                          _vm.enabledFields =
                                                            $$c
                                                        }
                                                      },
                                                    },
                                                  }),
                                                ]
                                              : [
                                                  _c("input", {
                                                    staticStyle: {
                                                      visibility: "hidden",
                                                    },
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled: true,
                                                    },
                                                    domProps: { checked: true },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    attrs: {
                                                      type: "hidden",
                                                      name: "input_fields[]",
                                                    },
                                                    domProps: {
                                                      value: fieldKey,
                                                      checked: true,
                                                    },
                                                  }),
                                                ],
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "field-name" },
                                              [
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      _vm.fieldMap[fieldKey]
                                                        .value.EN
                                                    ) +
                                                    "\n                                            "
                                                ),
                                                _vm.data.language !== "EN" &&
                                                _vm.fieldMap[fieldKey].custom
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "label label-default",
                                                        staticStyle: {
                                                          "margin-left": "0px",
                                                        },
                                                      },
                                                      [_vm._v("EN")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.fieldMap[fieldKey].custom &&
                                            _vm.data.language !== "EN"
                                              ? _c(
                                                  "span",
                                                  { staticClass: "field-name" },
                                                  [
                                                    _vm.fieldMap[fieldKey]
                                                      .value[_vm.data.language]
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                _vm.fieldMap[
                                                                  fieldKey
                                                                ].value[
                                                                  _vm.data
                                                                    .language
                                                                ]
                                                              ) +
                                                              " \n                                            "
                                                          ),
                                                        ])
                                                      : _c("span", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color: "red",
                                                              },
                                                            },
                                                            [_vm._v("<blank>")]
                                                          ),
                                                        ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "label label-default",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.data.language
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.showMapping
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "field-mapping-div",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Mapping:"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .dataFieldMapping1[
                                                                fieldKey
                                                              ],
                                                            expression:
                                                              "dataFieldMapping1[fieldKey]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isReadOnly(),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              _vm.dataFieldMapping1,
                                                              fieldKey,
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.dataFieldMappingFields,
                                                        function (label, key) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: key,
                                                              domProps: {
                                                                value: key,
                                                                selected:
                                                                  _vm.isSelected(
                                                                    _vm.dataFieldMapping1,
                                                                    fieldKey,
                                                                    key
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(label)
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .dataFieldMapping2[
                                                                fieldKey
                                                              ],
                                                            expression:
                                                              "dataFieldMapping2[fieldKey]",
                                                          },
                                                        ],
                                                        attrs: {
                                                          disabled:
                                                            _vm.isReadOnly(),
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              _vm.dataFieldMapping2,
                                                              fieldKey,
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.dataFieldMappingFields,
                                                        function (label, key) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: key,
                                                              domProps: {
                                                                value: key,
                                                                selected:
                                                                  _vm.isSelected(
                                                                    _vm.dataFieldMapping2,
                                                                    fieldKey,
                                                                    key
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(label)
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.showDeleteBtn(fieldKey)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "pull-right" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.enabledFields,
                                                          expression:
                                                            "enabledFields",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        visibility: "hidden",
                                                      },
                                                      attrs: {
                                                        type: "checkbox",
                                                        disabled:
                                                          _vm.isFieldRequired(
                                                            fieldKey
                                                          ) || _vm.isReadOnly(),
                                                      },
                                                      domProps: {
                                                        value: fieldKey,
                                                        checked: Array.isArray(
                                                          _vm.enabledFields
                                                        )
                                                          ? _vm._i(
                                                              _vm.enabledFields,
                                                              fieldKey
                                                            ) > -1
                                                          : _vm.enabledFields,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$a =
                                                              _vm.enabledFields,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = fieldKey,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                (_vm.enabledFields =
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ]))
                                                            } else {
                                                              $$i > -1 &&
                                                                (_vm.enabledFields =
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    ))
                                                            }
                                                          } else {
                                                            _vm.enabledFields =
                                                              $$c
                                                          }
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "btn-danger btn-sm",
                                                        attrs: {
                                                          href: "Javascript:void(0);",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeCustomField(
                                                              fieldKey
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.groupingFieldShow
              ? _c("div", [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "control-label form-label",
                      attrs: { for: "grouping_field" },
                    },
                    [_vm._v("Grouping Field")]
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.groupingField,
                          expression: "groupingField",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "grouping_field",
                        placeholder: "Grouping Field (optional)",
                        disabled: _vm.isReadOnly(),
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.groupingField = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _vm._l(_vm.groupingFields, function (groupingField) {
                        return [
                          _c(
                            "option",
                            {
                              key: groupingField.key,
                              domProps: { value: groupingField.key },
                            },
                            [_vm._v(_vm._s(groupingField.value.EN))]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          !_vm.isReadOnly()
            ? _c("add-field", {
                attrs: {
                  id: _vm.id,
                  doption: _vm.doption,
                  language: _vm.language,
                  companyLanguages: _vm.companyLanguages,
                },
                on: { "field-added": _vm.onFieldAdded },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pull-left" }, [
      _c("h4", { staticClass: "margin-top-20" }, [_vm._v("Reorder Fields")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }