var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    (_vm.data.messages || []).length
      ? _c(
          "div",
          { staticClass: "alert alert-info" },
          [
            _vm._l(_vm.data.messages || [], function (message) {
              return [_vm._v("\n            " + _vm._s(message) + "\n        ")]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c(
          "label",
          { staticClass: "control-label", attrs: { for: "name[EN]" } },
          [_vm._v("Name")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.name.EN,
              expression: "name.EN",
            },
          ],
          staticClass: "form-control required",
          attrs: { type: "text", placeholder: "Name" },
          domProps: { value: _vm.name.EN },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.name, "EN", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass:
          "table table-responsive table-valign-middle table-data-source margin-top-20",
        attrs: { id: "table_data_source" },
      },
      [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th"),
              _vm._v(" "),
              _vm._l(_vm.rowFields, function (field) {
                return _c("th", { key: field.key }, [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(field.value.EN) },
                  }),
                ])
              }),
              _vm._v(" "),
              _c("th", [_vm._v("Attributes")]),
              _vm._v(" "),
              _c("th", [_vm._v("Delete")]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "draggable",
          {
            attrs: {
              list: _vm.customFields,
              "ghost-class": "ghost",
              handle: ".item_dragger",
              tag: "tbody",
            },
            on: {
              start: function ($event) {
                _vm.drag = true
              },
              end: _vm.onEnd,
            },
          },
          _vm._l(_vm.customFields, function (customField) {
            return _c(
              "tr",
              { key: customField.key },
              [
                _c("td", [
                  _c("span", {
                    staticClass:
                      "glyphicon glyphicon-move dragger item_dragger",
                  }),
                ]),
                _vm._v(" "),
                _vm._l(_vm.rowFields, function (rowField) {
                  return _c(
                    "td",
                    { key: rowField.key },
                    [
                      rowField.key === "values"
                        ? [
                            _c(
                              "a",
                              {
                                class: `btn btn-sm btn-primary ${
                                  !_vm.hasSelectableValues(customField.type)
                                    ? "disabled"
                                    : ""
                                }`,
                                attrs: {
                                  disabled: !_vm.hasSelectableValues(
                                    customField.type
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.editValues($event, customField)
                                  },
                                },
                              },
                              [_c("span", { staticClass: "fas fa-list" })]
                            ),
                          ]
                        : rowField.key === "type"
                        ? [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: customField.type,
                                    expression: "customField.type",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        customField,
                                        "type",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.selectType($event, customField)
                                    },
                                  ],
                                },
                              },
                              [
                                _vm._l(
                                  rowField.values.basic,
                                  function (label, optionKey) {
                                    return [
                                      _c(
                                        "option",
                                        {
                                          key: optionKey,
                                          attrs: {
                                            disabled:
                                              _vm.isFieldTypeOptionDisabled(
                                                customField,
                                                optionKey
                                              ),
                                          },
                                          domProps: { value: optionKey },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(label) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        : [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    customField.value[
                                      rowField.key.replace(
                                        /.*\[(.*)\]/g,
                                        (match, p1) => `${p1}`
                                      )
                                    ],
                                  expression:
                                    "customField.value[rowField.key.replace(/.*\\[(.*)\\]/g, (match, p1) => `${p1}`)]",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                "data-validate": rowField["data-validate"],
                                "data-length": rowField["data-length"],
                              },
                              domProps: {
                                value:
                                  customField.value[
                                    rowField.key.replace(
                                      /.*\[(.*)\]/g,
                                      (match, p1) => `${p1}`
                                    )
                                  ],
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    customField.value,
                                    rowField.key.replace(
                                      /.*\[(.*)\]/g,
                                      (match, p1) => `${p1}`
                                    ),
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ],
                    ],
                    2
                  )
                }),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-sm btn-primary attributes_edit",
                      on: {
                        click: function ($event) {
                          return _vm.editAttributes($event, customField)
                        },
                      },
                    },
                    [_c("span", { staticClass: "fas fa-pencil-alt" })]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      staticClass: "btn-danger btn btn-sm",
                      attrs: { href: "Javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.removeCustomField(customField.key)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-trash",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ]),
              ],
              2
            )
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "a",
            {
              staticClass: "blue margin-top-10 btn btn-sm btn-primary",
              attrs: { href: "Javascript:void(0);" },
              on: { click: _vm.addCustomField },
            },
            [
              _c("i", {
                staticClass: "fas fa-plus",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" Add New Field\n                "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6 margin-top-10" }, [
            _c("b", [_vm._v("Other Features")]),
            _vm._v(" "),
            _vm.availableFeaturesMap.status
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedFeaturesMap.status,
                        expression: "selectedFeaturesMap.status",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedFeaturesMap.status)
                        ? _vm._i(_vm.selectedFeaturesMap.status, null) > -1
                        : _vm.selectedFeaturesMap.status,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.selectedFeaturesMap.status,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "status",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "status",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.selectedFeaturesMap, "status", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(
                    "\n                        Status (New records require approval)\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.availableFeaturesMap.expire_date
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedFeaturesMap.expire_date,
                        expression: "selectedFeaturesMap.expire_date",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(
                        _vm.selectedFeaturesMap.expire_date
                      )
                        ? _vm._i(_vm.selectedFeaturesMap.expire_date, null) > -1
                        : _vm.selectedFeaturesMap.expire_date,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.selectedFeaturesMap.expire_date,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "expire_date",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "expire_date",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.selectedFeaturesMap, "expire_date", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(
                    "\n                        Expiry Date (Turn on record expiration options)\n                    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.availableFeaturesMap.gps
              ? _c("div", { staticClass: "col-sm-12" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedFeaturesMap.gps,
                        expression: "selectedFeaturesMap.gps",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.selectedFeaturesMap.gps)
                        ? _vm._i(_vm.selectedFeaturesMap.gps, null) > -1
                        : _vm.selectedFeaturesMap.gps,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.selectedFeaturesMap.gps,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "gps",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.selectedFeaturesMap,
                                "gps",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.selectedFeaturesMap, "gps", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(
                    "\n                        Location (Store GPS location of user's device)\n                    "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6 margin-top-10" }, [
            _c("b", [_vm._v("External API Settings:")]),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "margin-top-10" }, [
              _c(
                "a",
                {
                  staticClass: "blue",
                  attrs: { href: "Javascript:void(0);" },
                  on: { click: _vm.toggleApiSettings },
                },
                [
                  _vm._v(
                    "\n                            View Details\n                        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.apiSettingsVisible
              ? _c("div", { staticClass: "margin-top-10" }, [
                  _vm._v(
                    "\n                        Data Source: " + _vm._s(_vm.id)
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                        Entity: " +
                      _vm._s(_vm.data.database_name)
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v("\n                        Fields:"),
                  _c("br"),
                  _vm._v(" "),
                  _c("table", { staticClass: "table" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.customFields, function (customField) {
                        return _c(
                          "tr",
                          { key: customField.key },
                          [
                            !_vm.availableFeaturesMap[customField.key]
                              ? [
                                  _c("td", [_vm._v(_vm._s(customField.key))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.getFieldLabel(customField))
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: "__vue_json_data" },
      domProps: { value: _vm.vueJsonData },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Key")]),
        _vm._v(" "),
        _c("th", [_vm._v("Field")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }