var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.listData,
            "ghost-class": "ghost",
            handle: ".item_dragger",
          },
          on: {
            start: function ($event) {
              _vm.drag = true
            },
            end: _vm.onEnd,
          },
        },
        [
          _vm._l(_vm.listData, function (listItem, index) {
            return [
              _c(
                "div",
                { key: index, staticClass: "input-group margin-top-10" },
                [
                  _c("span", { staticClass: "input-group-addon" }, [
                    _c("span", {
                      staticClass:
                        "glyphicon glyphicon-move dragger item_dragger",
                    }),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: listItem.display,
                        expression: "listItem.display",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: listItem.display },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(listItem, "display", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "input-group-btn" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn-danger btn",
                        on: {
                          click: function ($event) {
                            return _vm.removeRow(index)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-trash",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "margin-top-20" }, [
        _c(
          "a",
          {
            staticClass: "blue",
            attrs: { href: "#" },
            on: { click: _vm.addRow },
          },
          [
            _c("i", {
              staticClass: "fas fa-plus",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Add New Value\n        "),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }